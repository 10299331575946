@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$primary: #0a1d56;
$secondary: #626bff;
$black: #000;
$white: #fff;
$bgc: darken($white, 3%);
$warm: #f44336;
$active: #04773d;
$inactive: #bc0808;
$draft: #786200;
$fontFace: 'Open Sans', sans-serif;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1000px,
  // Extra large screen / wide desktop
  xl: 1600px
);
